/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
:root {
  --bg-color-for-sidenav: #F8F8F8 !important;
}

.custom-scrollbar {
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #a0b3b7 #f5f5f5;
  /* Thumb color and Track color */

  max-height: "100%";

}

.custom-scrollbarX {

  overflow-x: scroll;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #a0b3b7 #f5f5f5;
  /* Thumb color and Track color */

  max-height: "100%";

}

/* Webkit browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
  background-color: #f5f5f5;
  /* Track background color */
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  /* Track background color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #81B5E2;
  /* Thumb color */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);

}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #4A90E2;
  /* Darker color on hover */
}

/* Optional: Hide scrollbar for IE and Edge */
.custom-scrollbar {
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.custom-scrollbar::-ms-scrollbar {
  display: none;
  /* For Internet Explorer and Edge */
}