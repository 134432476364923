.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-container {
  display: flex !important;
  flex-wrap: wrap !important;
}

.flex-item {
  width: calc(33.33% - 20px); 
  margin: 10px;
 
}
.accordion-item {
  --bs-accordion-active-bg: transparent;
}

/* .accordion-button::before {
  content: none; 
} */
.accordion-button::after {
  content: none; /* Remove the content */
}


.bg-light-green {
  background-color: #c4f7c4; /* Light green background color */
}

/* .border-secondary {
  border-color: #e8e8e8; 
} */

.bg-white{
  background-color: white;
}
.border-num{
  border-color: rgb(223, 222, 222);
  border-radius: 20rem;
  border-width: 2rem;
}